import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import CaretIcon from "../assets/svg/icons/caret.svg"

const JobPage = () => (
  <Layout>
    <Seo title="Jobs" />
    <section className="page">
      <h1 className="text_huge">Jobs</h1>
      <ul className="nodotList">
        <li className="job-icon">
          <CaretIcon />
          <Link className="no-decoration" to="typo3-entwickler">
            <h2 className="text_big">TYPO3 Entwickler (m/w)</h2>
            <span>(38,5h/Woche, in Bregenz)</span>
          </Link>
        </li>
        <li className="job-icon">
          <CaretIcon />
          <Link className="no-decoration" to="php-web-entwickler">
            <h2 className="text_big">PHP Web Entwickler (m/w)</h2>
            <span>(38,5h/Woche, in Bregenz)</span>        
          </Link>
        </li>
        <li className="job-icon">
          <CaretIcon />
          <Link className="no-decoration" to="junior-web-entwickler">
            <h2 className="text_big">Junior Web Entwickler (m/w)</h2>
            <span>(38,5h/Woche, in Bregenz)</span>        
          </Link>
        </li>
      </ul>
    </section>
  </Layout>
)

export default JobPage
